/* --- MOBILE STYLES --- */

.landing .name {
  font-size: 48px;
}

.landing .description {
  font-size: 22px;
}

.landingImage {
  right: 0px;
}

.skillsAnimation {
  width: 200px;
}

.landing .socialIcons {
  position: absolute;
  top: 20px;
  left: 45px;
  scale: 1.5;
  transition: all;
}

/* --- MEDIA QUERIES --- */

@media (min-width: 750px) {
  .landing .name {
    font-size: 70px;
  }

  .landing .description {
    font-size: 32px;
  }

  .landingImage {
    bottom: 0;
    right: 80px;
  }

  .landing .socialIcons {
    scale: 1;
    top: 15px;
  }
}