.dashboardTitle td,
.dashboardTitle th,
.dashboardTitle tr {
    color: rgb(149, 149, 149);
    padding: 5px 10px;
    background-color: #2b2b2b;
    border: 1px inset #4b4b4b;
}

.dashboardTitle td {
    color: rgb(209, 209, 209);
}

.dashboardTitle td.list-project-color {
    width: 100px
}